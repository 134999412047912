import React, { ReactNode, memo, useEffect, useState } from 'react';
import { fetchAndActivate } from 'firebase/remote-config';

import Loader from 'app/components/Loader';

import { getAuth } from '@firebase/auth';
import { useAppSelector } from 'store/configureStore';
import { tenantSettingsSelector } from 'store/selectors/tenant-settings';
import { app, remoteConfig } from './firebase';

interface FirebaseRemoteConfigProviderInterface {
  children: ReactNode;
}
const auth = getAuth(app);

const FirebaseRemoteConfigInitialization: React.FC<
  FirebaseRemoteConfigProviderInterface
> = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const tenantSettings = useAppSelector(tenantSettingsSelector);

  useEffect(() => {
    fetchAndActivate(remoteConfig).then(() => {
      setIsInitialized(true);
    });
  }, []);

  useEffect(() => {
    auth.tenantId = tenantSettings?.firebaseTenantId as string;
  }, [tenantSettings]);

  if (!isInitialized) {
    return <Loader withText={false} />;
  }

  return <>{children}</>;
};

export { auth };
export default memo(FirebaseRemoteConfigInitialization);
