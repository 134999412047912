import React, { Fragment, memo, useCallback, useMemo, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { HeaderTabs } from '_constants/headerConstants';

import { LoginFormType } from '_types/modals.interface';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { profileRoleSelector } from 'store/selectors/profile';
import { tenantSettingsTenantFeatureFlagsSelector } from 'store/selectors/tenant-settings';
import {
  actionHandleShowLoginModal,
  actionHandleShowMenuModal,
} from 'store/reducers/modal';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import useTenantType from 'utils/hooks/useTenantType';

import useTenantPageConfiguration from 'utils/hooks/useTenantPageConfiguration';
import { PageConfigurationTypeEnum } from '_types/tenant-settings/tenant-page-configuration';
import { headerTabsConstantsFn } from './utils';
import './style.scss';

interface HomePageTabsProps {
  inModal?: boolean;
  withDividers?: boolean;
  className?: string;
  isForUnAuthHomePage?: boolean;
}

const HomePageTabs: React.FC<HomePageTabsProps> = ({
  inModal = false,
  withDividers = false,
  className,
  isForUnAuthHomePage = false,
}) => {
  const { t } = useTenantTranslation();
  const dispatch = useAppDispatch();
  const tabRefs = useRef<Map<string, HTMLAnchorElement | null>>(new Map());

  const tenantType = useTenantType();
  const tenantFeatureFlags = useAppSelector(
    tenantSettingsTenantFeatureFlagsSelector,
  );
  // const tenantStyling = useAppSelector(tenantSettingsTenantStylingSelector);
  const userRole = useAppSelector(profileRoleSelector);
  const { pageConfiguration } = useTenantPageConfiguration(
    PageConfigurationTypeEnum.UNAUTH_HOME_PAGE,
  );

  const headerTabsConstants = useMemo(() => {
    let headerTabs = headerTabsConstantsFn(
      userRole,
      tenantFeatureFlags,
      t,
      tenantType,
      pageConfiguration,
    );
    if (isForUnAuthHomePage) {
      headerTabs = headerTabs.filter((header) => header?.isForUnAuthHomePage);
    } else {
      headerTabs = headerTabs.filter((header) => !header?.isForUnAuthHomePage);
    }
    return headerTabs;
  }, [
    userRole,
    tenantFeatureFlags,
    t,
    tenantType,
    isForUnAuthHomePage,
    pageConfiguration,
  ]);

  const closeModal = useCallback(() => {
    dispatch(actionHandleShowMenuModal(false));
  }, [dispatch]);

  const onLoginClick = useCallback(() => {
    dispatch(actionHandleShowMenuModal(false));
    dispatch(actionHandleShowLoginModal({ formType: LoginFormType.login }));
  }, [dispatch]);

  if (!headerTabsConstants?.length) {
    return null;
  }

  const handleScrollToElement = (to: string) => {
    const element = document.getElementById(to.replace('#', ''));

    const tabElement = tabRefs.current.get(to);
    if (tabElement) {
      tabRefs.current.forEach((ref) => ref?.classList.remove('active'));
      tabElement.classList.add('active');
    }

    if (element) {
      window.scrollTo({
        top: element.offsetTop - 75,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div
      className={clsx('home_tabs', className, {
        'with-dividers': withDividers,
        'in-modal': inModal,
      })}
    >
      {headerTabsConstants?.map((elem: HeaderTabs, index) => (
        <Fragment key={elem.name}>
          <NavLink
            ref={(el) => tabRefs.current.set(elem.path, el)}
            to={elem.path}
            className={({ isActive }) =>
              clsx('home_tabs_item', {
                active:
                  window.location.pathname === elem.path ||
                  window.location.hash === elem.path,
              })
            }
            key={elem.name}
            onClick={(e) => {
              if (elem.path === '#') {
                onLoginClick();
              } else if (elem.path.includes('#')) {
                e.preventDefault();
                window.history.pushState(null, '', elem.path);
                handleScrollToElement(elem.path);
              } else {
                closeModal();
              }
            }}
          >
            {/* <div className="home_tabs_item__icon">{elem.getIcon(tenantStyling?.shades08)}</div> */}
            <div className="home_tabs_item__text">{elem.name}</div>
          </NavLink>

          {withDividers && index !== headerTabsConstants.length - 1 && (
            <div className="tabs-horizontal-divider" />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default memo(HomePageTabs);
