import { createAsyncThunk } from '@reduxjs/toolkit';

import { PartnerResourcesSearchType } from '_types/partner-resources.interface';

import partnerResourcesService from '_services/partnerResourcesService';

type FetchGetPartnerResourcesCardsDataType<PartnerResourcesSearchType> = {
  searchPayload: PartnerResourcesSearchType;
  partnerResourcesType?: string;
};

export const fetchGetPartnerResourcesCards = createAsyncThunk(
  'partner-resources/fetch-partner-resources',
  (
    data: FetchGetPartnerResourcesCardsDataType<PartnerResourcesSearchType>,
    { rejectWithValue },
  ) =>
    partnerResourcesService
      .getPartnerResourcesList(data.searchPayload, data.partnerResourcesType)
      .catch(rejectWithValue),
);

export const fetchPartnerResourcesFilterSettings = createAsyncThunk(
  'advisor/fetchPartnerResourcesFilterSettings',
  async (_, { rejectWithValue }) => {
    try {
      const response =
        await partnerResourcesService.getPartnerResourcesFilterSettings();

      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
