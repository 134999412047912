import React, { memo } from 'react';
import clsx from 'clsx';

import { TenantLegalDocumentTypeEnum } from '_types/tenant-settings';

import LogoIconPlain from 'app/components/Icons/LogoIconPlain';
import TenantLegalDocumentLink from 'app/components/TenantLegalDocumentLink';

import { useAppSelector } from 'store/configureStore';

import { profileRoleSelector } from 'store/selectors/profile';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import { getTermsOfUseDocumentTypeByRole } from 'utils/tenantLegalDocument';

import { tenantSettingsAccessibilitySelector } from 'store/selectors/tenant-settings';
import FooterWrapper from '../components/FooterWrapper';

import './style.scss';

interface GeneralFooterProps {
  hideOnMobile?: boolean;
}

const GeneralFooter: React.FC<GeneralFooterProps> = ({ hideOnMobile }) => {
  const { t } = useTenantTranslation();
  const profileRole = useAppSelector(profileRoleSelector);
  const accessibility = useAppSelector(tenantSettingsAccessibilitySelector);

  return (
    <FooterWrapper>
      <div className={clsx('footer_wrapper__home', { hidden: hideOnMobile })}>
        <div className="footer_wrapper__home_desktop">
          <p>{t('misc.footer_left_privacy_text')}</p>
          <div className="poweredBy">
            <p>{t('misc.powered_by')}</p>

            <LogoIconPlain />
          </div>
          <p>
            {accessibility && (
              <>
                <a href="#" data-acsb-custom-trigger="true">
                  {t('misc.accessibility')}
                </a>
                &nbsp; &nbsp;
              </>
            )}
            <TenantLegalDocumentLink
              documentType={getTermsOfUseDocumentTypeByRole(profileRole)}
            />
            &nbsp;-&nbsp;
            <TenantLegalDocumentLink
              documentType={TenantLegalDocumentTypeEnum.PRIVACY_POLICY}
            />
          </p>
        </div>

        {/* <HomePageTabs /> */}
      </div>
    </FooterWrapper>
  );
};

export default memo(GeneralFooter);
