import { UnauthHomePageConfigrationDataJSON } from './unauth-home-page';

export enum PageConfigurationTypeEnum {
  UNAUTH_HOME_PAGE = 'UNAUTH_HOME_PAGE',
}

export type PageConfigurationDataJSON = UnauthHomePageConfigrationDataJSON;

export type TenantPageConfigurationEntity = {
  id: number;
  type: PageConfigurationTypeEnum;
  pageConfigurationData: PageConfigurationDataJSON;
};
