import { createSlice } from '@reduxjs/toolkit';

import {
  PartnerResourcesFilterSettings,
  PartnerResourcesItemType,
} from '_types/partner-resources.interface';

import {
  fetchGetPartnerResourcesCards,
  fetchPartnerResourcesFilterSettings,
} from 'store/actions/partner-resources';

interface PartnerResourcesState {
  partnerResourcesCount: number;
  partnerResourcesList: PartnerResourcesItemType[];
  isLoading: boolean;
  isPartnerResourcesFilterDetailsLoading: boolean;
  partnerResourcesFilterDetails: PartnerResourcesFilterSettings;
}

const partnerResourcesInitialState: PartnerResourcesState = {
  partnerResourcesCount: 0,
  partnerResourcesList: [],
  isLoading: false,
  isPartnerResourcesFilterDetailsLoading: false,
  partnerResourcesFilterDetails: {} as PartnerResourcesFilterSettings,
};

const partnerResourcesSlice = createSlice({
  name: 'partner-resources',
  initialState: partnerResourcesInitialState,
  reducers: {
    resetState: () => partnerResourcesInitialState,
    resetPartnerResourcesState: (state) => {
      state.partnerResourcesCount = 0;
      state.partnerResourcesList = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetPartnerResourcesCards.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchGetPartnerResourcesCards.fulfilled,
      (state, action) => {
        state.partnerResourcesCount = action.payload.count;
        state.partnerResourcesList = action.payload.partnerResources;
        state.isLoading = false;
      },
    );
    builder.addCase(fetchGetPartnerResourcesCards.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchPartnerResourcesFilterSettings.pending, (state) => {
      state.isPartnerResourcesFilterDetailsLoading = true;
    });
    builder.addCase(
      fetchPartnerResourcesFilterSettings.fulfilled,
      (state, action) => {
        state.isPartnerResourcesFilterDetailsLoading = false;
        state.partnerResourcesFilterDetails = action.payload;
      },
    );
    builder.addCase(
      fetchPartnerResourcesFilterSettings.rejected,
      (state, _action) => {
        state.isPartnerResourcesFilterDetailsLoading = false;
      },
    );
  },
});

export default partnerResourcesSlice;
