import { ReactNode } from 'react';
import { TFunction } from 'i18next';

export const processNonMFALoginError = (
  t: TFunction,
  error: any,
): ReactNode => {
  switch (error.code) {
    case 'auth/wrong-password':
    case 'auth/invalid-email':
    case 'auth/invalid-login-credentials':
      return t('validation.invalid_email_or_password');
    case 'auth/too-many-requests':
      return t('auth.too_many_attempts_try_later');
    default:
      break;
  }

  const firebaseResponseErrorMessage = error?.response?.data?.error?.message;
  if (firebaseResponseErrorMessage === 'CREDENTIAL_TOO_OLD_LOGIN_AGAIN') {
    return t('auth.relogin_and_try_again');
  }
  if (firebaseResponseErrorMessage === 'TOO_MANY_ATTEMPTS_TRY_LATER') {
    return t('auth.too_many_attempts_try_later');
  }

  const loginResponseErrorMessage = error?.response?.data?.message;
  if (loginResponseErrorMessage === 'Failed attempts number exceeded') {
    return t('auth.failed_attempts_number_exceeded');
  }
  if (loginResponseErrorMessage === 'Invalid credentials') {
    return t('validation.invalid_email_or_password');
  }

  // eslint-disable-next-line no-console
  console.error(error);

  return t('misc.error_occurred');
};
