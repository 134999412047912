import clsx from 'clsx';
import React, { memo, useCallback, useState } from 'react';
import { CiWarning } from 'react-icons/ci';

import './style.scss';
import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import { useAppDispatch, useAppSelector } from 'store/configureStore';
import {
  actionHandleShowAccountDeletionModal,
  actionHandleShowInfoModal,
} from 'store/reducers/modal';
import { Trans } from 'react-i18next';
import Button from 'app/components/Button';
import CheckedIcon from 'app/components/Icons/CheckedIcon';
import { deleteSboAccount } from 'store/actions/user';
import { profileInfoEmailSelector } from 'store/selectors/profile';
import CongratulationsIcon from 'app/components/Icons/CongratulationsIcon';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { auth } from 'utils/firebase/FirebaseRemoteConfigInitialization';
import useTenantColors from 'utils/hooks/useTenantColors';
import BaseModal from '../BaseModal';

interface ResetACcountDeletionProps {
  classNames?: string;
}

const AccountDeletionModal: React.FC<ResetACcountDeletionProps> = ({
  classNames,
}) => {
  const navigate = useNavigate();
  const { t } = useTenantTranslation();
  const dispatch = useAppDispatch();
  const [isShowSuccessModal, setIsShowSuccessModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(false);
  const emailAddress = useAppSelector(profileInfoEmailSelector);
  const colors = useTenantColors();

  const closeModal = useCallback(async () => {
    dispatch(actionHandleShowAccountDeletionModal(false));
  }, []);

  const deleteAccount = useCallback(async () => {
    if (selected) {
      const result = await dispatch(deleteSboAccount(emailAddress as string));
      if (deleteSboAccount.fulfilled.match(result)) {
        setIsShowSuccessModal(true);
        closeModal();
        await sessionStorage.clear();

        dispatch(
          actionHandleShowInfoModal({
            closeOnBackgroundClick: true,
            headerIcon: (
              <CongratulationsIcon
                color={colors.colorVariables?.text.text_accent_1}
                className="icon"
              />
            ),
            modalTitle: t('misc.deletion_success_message.title'),
            content: t('misc.deletion_success_message.description'),
            closeButtonText: t('misc.deletion_success_message.button'),
            onModalClose: async () => {
              await auth.signOut();
              navigate('/');
            },
            classNames: {
              modalTitle: 'deletion_modal_title',
            },
          }),
        );
      }
      if (deleteSboAccount.rejected.match(result)) {
        const error = result.payload as AxiosError;
        const status = error?.message;
        const message = error.response?.data;
        window.alert(`${status} : ${message}`);
      }
    }
  }, [emailAddress, selected]);

  return (
    <BaseModal
      headerIcon={<CiWarning className="icon" size={40} color="#BA2C2B" />}
      modalTitle={t('misc.delete_account_modal.header_text')}
      closeOnBackgroundClick
      withDeclineButton
      declineButtonText={t('misc.cancel')}
      withAcceptButton
      onDeclineButtonClick={closeModal}
      onModalClose={closeModal}
      onAcceptButtonClick={deleteAccount}
      acceptButtonText={t('misc.delete_account_modal.confirmation_button_text')}
      classNames={{
        modal: 'account_deletion__modal',
        modalTitle: 'modal_block__title',
        modalHeader: 'modal_block__header',
        acceptButton: 'modal_block__acceptButton',
        declineButton: 'modal_block__declineButton',
        closeIcon: 'modal_block__closeIcon',
        modalFooter: 'modal_block__footer',
      }}
      disableAcceptButton={!selected}
      isCloseIconVisible
      declineButtonSize="large"
      acceptButtonSize="large"
    >
      <div className="modal_block__body">
        <div className="content">
          <p>
            <Trans components={[<span key="0" />]}>
              {t('misc.delete_account_modal.body_description1')}
            </Trans>
          </p>
          <p>{t('misc.delete_account_modal.body_description2')}</p>
        </div>
        <div className="checkbox" onClick={() => setSelected(!selected)}>
          <div className={clsx('selectable_box', { active: selected })}>
            {selected && <CheckedIcon />}
          </div>
          <p>{t('misc.delete_account_modal.confirmation_message')}</p>
        </div>
      </div>
    </BaseModal>
  );
};

export default memo(AccountDeletionModal);
