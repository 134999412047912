import {
  PageConfigurationDataJSON,
  PageConfigurationTypeEnum,
} from '_types/tenant-settings/tenant-page-configuration';
import { useEffect, useState } from 'react';
import { fetchGetTenantPageConfiguration } from 'store/actions/tenant-settings';
import { useAppDispatch } from 'store/configureStore';

const useTenantPageConfiguration = (
  type: PageConfigurationTypeEnum,
): {
  loading: boolean;
  pageConfiguration: PageConfigurationDataJSON | null;
} => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [pageConfiguration, setPageConfiguration] =
    useState<PageConfigurationDataJSON | null>(null);

  useEffect(() => {
    setLoading(true);

    dispatch(fetchGetTenantPageConfiguration(type))
      .unwrap()
      .then((data) => {
        setPageConfiguration(data.pageConfigurationData);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [type]);

  return { loading, pageConfiguration };
};

export default useTenantPageConfiguration;
