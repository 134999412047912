import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  AdvisorInvitation,
  PatchProfileData,
  SetUserCookieConsentRequestBody,
} from '_types/profile.interface';

import ProfileService from '_services/profileService';
import UserService from '_services/userService';
import { auth } from 'utils/firebase/FirebaseRemoteConfigInitialization';

export const fetchGetProfileInfo = createAsyncThunk(
  'user/fetchGetProfileInfo',
  async (_, { rejectWithValue }) => {
    try {
      return await ProfileService.getProfileData();
    } catch (error: any) {
      if (error?.response?.data?.message === 'Expired token') {
        sessionStorage.clear();
        await auth.signOut();
      }
      return rejectWithValue(error);
    }
  },
);

export const fetchPatchProfileInfo = createAsyncThunk(
  'profile/fetchPatchProfileInfo',
  async (patchProfileData: PatchProfileData, { rejectWithValue }) => {
    try {
      return await ProfileService.patchProfileData(patchProfileData);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchAddAdvisorInvitations = createAsyncThunk(
  'profile/fetchAddAdvisorInvitations',
  async (advisorInvitations: AdvisorInvitation[], { rejectWithValue }) => {
    try {
      return await ProfileService.patchAdvisorInvitations({
        invitationsToAdd: advisorInvitations,
      });
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchDeleteAdvisor = createAsyncThunk(
  'profile/fetchDeleteAdvisor',
  async (email: string, { rejectWithValue }) => {
    try {
      await ProfileService.deleteAdvisor(encodeURIComponent(email));

      return email;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchSetUserCookieConsent = createAsyncThunk(
  'profile/fetchSetUserCookieConsent',
  async (data: SetUserCookieConsentRequestBody, { rejectWithValue }) => {
    try {
      return await UserService.setCookieConsent(data);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
