import React, { memo, useCallback, useMemo } from 'react';
import Rate from 'rc-rate';
import 'rc-rate/assets/index.css';

import { rateStepsText } from '_constants/advisorConstants';

import Textarea from 'app/components/Textarea/Textarea';
import SelectButtonSimple from 'app/components/SelectButtonNew/SelectButtonSimple';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

interface StepTimeSelectorProps {
  setRate: (rate: (string | number | boolean)[]) => void;
  step: number;
  totalSteps: number;
  rate: (string | number | boolean)[];
  advisor: {
    firstName: string;
    lastName: string;
  };
}

const StepRateSelectorComponent: React.FC<StepTimeSelectorProps> = ({
  totalSteps,
  setRate,
  step,
  rate,
  advisor,
}) => {
  const { t } = useTenantTranslation();

  const onChange = useCallback(
    (value: number | boolean | string) => {
      const newRate: any = [...rate];
      newRate[step] = value;
      setRate(newRate);
    },
    [step, rate, setRate],
  );

  const rateText = useMemo(
    () => rateStepsText(t, advisor)[step],
    [t, advisor, step],
  );

  return (
    <>
      <h2 className="modal_body_title">{rateText.title}</h2>
      {rateText?.subtitle && (
        <p className="modal_body_subtitle">{rateText.subtitle}</p>
      )}
      {step !== totalSteps && step !== 0 ? (
        <>
          <Rate
            value={Number(rate[step]) || 0}
            allowClear={false}
            onChange={onChange}
          />

          <div className="modal_body_skip">
            <p>{t('misc.not_for_me')}</p>
            <p>{t('misc.love_it')}</p>
          </div>
        </>
      ) : step === 0 ? (
        <div className="selectors_container">
          <SelectButtonSimple
            key="1"
            value={t('misc.Yes')}
            title={t('misc.yes')}
            onClick={() => onChange(true)}
            selected={rate[step] as boolean}
            type="select"
          />

          <SelectButtonSimple
            key="2"
            value={t('misc.No')}
            title={t('misc.no')}
            onClick={() => onChange(false)}
            selected={(rate[step] as boolean) === false}
            type="select"
          />
        </div>
      ) : (
        <Textarea
          type="text"
          placeholder=""
          value={String(rate[step] || '')}
          onChange={onChange}
        />
      )}
    </>
  );
};

export default memo(StepRateSelectorComponent);
