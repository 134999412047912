import { createSlice } from '@reduxjs/toolkit';

interface TranslationsState {
  namespacesLoaded: string[];
}

export const translationsSliceInitialState: TranslationsState = {
  namespacesLoaded: [],
};

const translationsSlice = createSlice({
  name: 'translations',
  initialState: translationsSliceInitialState,
  reducers: {
    resetState: () => translationsSliceInitialState,
    addLoadedTranslationNamespace: (state, action) => {
      state.namespacesLoaded.push(action.payload);
    },
  },
});

export const { resetState, addLoadedTranslationNamespace } =
  translationsSlice.actions;

export default translationsSlice;
