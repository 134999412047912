import {
  PaginatedPartnerResourcesResponseType,
  PartnerResourcesFilterSettings,
  PartnerResourcesSearchType,
} from '_types/partner-resources.interface';

import { TenantFilterTypeEnum } from '_types';
import axiosApiInstance from './axiosInstance';

const baseUrl = process.env.REACT_APP_BACKEND_DOMAIN;

const partnerResourcesService = {
  getPartnerResourcesList: (
    partnerResourcesSearchPayload: PartnerResourcesSearchType,
    partnerResourcesType?: string,
  ) =>
    axiosApiInstance
      .post<PaginatedPartnerResourcesResponseType>(
        `${baseUrl}/api/v1/partner-resources/fetch-partner-resources?type=${partnerResourcesType}`,
        partnerResourcesSearchPayload,
      )
      .then(({ data }) => data),
  getPartnerResourcesFilterSettings: () =>
    axiosApiInstance
      .get<PartnerResourcesFilterSettings>(
        `${baseUrl}/api/v1/tenant-settings/tenant-filter-settings/${TenantFilterTypeEnum.PARTNER_RESOURCE_FILTER}`,
      )
      .then(({ data }) => data),
};
export default partnerResourcesService;
