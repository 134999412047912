import React from 'react';
import { Trans } from 'react-i18next';

import { RateStepsType } from '_types/advisor.interface';

export const rateStepsText = (t, advisor): RateStepsType[] => [
  {
    title: (
      <Trans
        i18nKey="misc.rate_list.advisor_presence_title"
        values={{
          name: `${advisor.firstName} ${advisor.lastName}`,
        }}
        components={[<br key="br" />, <span key="name" />]}
      />
    ),
    // subtitle: t('misc.rate_list.advisor_presence_subtitle'),
  },
  {
    title: (
      <Trans components={[<br key="0" />, <span key="1" />]}>
        {t('misc.rate_list.experience_title', {
          name: `${advisor.firstName} ${advisor.lastName}`,
        })}
      </Trans>
    ),
    // subtitle: t('misc.rate_list.experience_subtitle'),
  },
  {
    title: (
      <Trans components={[<span key="0" />]}>
        {t('misc.rate_list.wouldRecommend_title', { name: advisor.firstName })}
      </Trans>
    ),
    // subtitle: t('misc.rate_list.wouldRecommend_subtitle'),
  },
  {
    title: (
      <Trans components={[<span key="0" />]}>
        {t('misc.rate_list.expertise_title', { name: advisor.firstName })}
      </Trans>
    ),
    // subtitle: t('misc.rate_list.expertise_subtitle'),
  },
  {
    title: t('misc.rate_list.feedback_title'),
    subtitle: t('misc.rate_list.feedback_subtitle'),
  },
];
