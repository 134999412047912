import React, { memo, useEffect, useMemo, useState } from 'react';
import { User } from '@firebase/auth';

import Loader from 'app/components/Loader';

import { auth } from 'utils/firebase/FirebaseRemoteConfigInitialization';
import { useIsPageTabInFocus } from 'utils/hooks/useIsPageTabInFocus';

import {
  FirebaseAuthContextData,
  FirebaseAuthContextDataWithoutReloadInterval,
  FirebaseReloadIntervalData,
  defaultFirebaseAuthContextData,
  defaultFirebaseReloadIntervalData,
} from './utils';

import CurrentFirebaseUserContext from '.';

interface FirebaseAuthDataContextProviderProps {
  children: any;
}

const FirebaseAuthDataContextProvider: React.FC<
  FirebaseAuthDataContextProviderProps
> = ({ children }) => {
  const [
    currentFirebaseUserReloadInterval,
    setCurrentFirebaseUserReloadInterval,
  ] = useState<FirebaseReloadIntervalData['firebaseUserReloadInterval']>(
    defaultFirebaseReloadIntervalData.firebaseUserReloadInterval,
  );

  const [
    currentFirebaseDataWithoutReloadInterval,
    setCurrentFirebaseDataWithoutReloadInterval,
  ] = useState<FirebaseAuthContextDataWithoutReloadInterval>(
    defaultFirebaseAuthContextData,
  );

  const isPageTabInFocus = useIsPageTabInFocus();
  const currentFirebaseUser =
    currentFirebaseDataWithoutReloadInterval.currentUser;

  const currentFirebaseData = useMemo(
    (): FirebaseAuthContextData => ({
      ...currentFirebaseDataWithoutReloadInterval,
      firebaseUserReloadInterval: currentFirebaseUserReloadInterval,
      setFirebaseUserReloadInterval: setCurrentFirebaseUserReloadInterval,
    }),
    [
      currentFirebaseDataWithoutReloadInterval,
      currentFirebaseUserReloadInterval,
      setCurrentFirebaseUserReloadInterval,
    ],
  );

  useEffect(() => {
    const onFirebaseStateChange = async (user: User | null) => {
      const accessToken = (await user?.getIdToken()) || null;

      setCurrentFirebaseDataWithoutReloadInterval({
        accessToken,
        currentUser: user,
        isFirebaseInitializing: false,
      });
    };

    const onIdTokenChangedUnsubscribe = auth.onIdTokenChanged(
      onFirebaseStateChange,
    );
    const onAuthStateChangedUnsubscribe = auth.onAuthStateChanged(
      onFirebaseStateChange,
    );

    return () => {
      onIdTokenChangedUnsubscribe();
      onAuthStateChangedUnsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!currentFirebaseUser || !isPageTabInFocus) {
      return () => {};
    }

    const reloadUser = async () => {
      await currentFirebaseUser.getIdToken();
      await currentFirebaseUser.reload();
    };

    reloadUser();

    const interval = setInterval(reloadUser, currentFirebaseUserReloadInterval);

    return () => clearInterval(interval);
  }, [
    !!currentFirebaseUser,
    isPageTabInFocus,
    currentFirebaseUserReloadInterval,
  ]);

  // return loader if is still initializing
  if (currentFirebaseData.isFirebaseInitializing) {
    return <Loader />;
  }

  return (
    <CurrentFirebaseUserContext.Provider value={currentFirebaseData}>
      {children}
    </CurrentFirebaseUserContext.Provider>
  );
};

export default memo(FirebaseAuthDataContextProvider);
