import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  UserRolesEnum,
  PatchUserType,
  RegistrationUserData,
} from '_types/user.interface';

import UserService from '_services/userService';

interface RegistrationType {
  role: string;
  userInfo: RegistrationUserData;
}
interface ChangeEmailType {
  newEmail: string;
  password: string;
}
export const fetchRegistration = createAsyncThunk(
  'user/fetchRegistration',
  async ({ role, userInfo }: RegistrationType, { rejectWithValue }) => {
    try {
      let response;
      switch (role) {
        case UserRolesEnum.SBO:
          response = await UserService.registrationSbo(userInfo);
          break;
        case UserRolesEnum.ADVISORY_ORG_ADMIN:
          response = await UserService.registrationAdvisoryOrgAdmin(userInfo);
          break;
        case UserRolesEnum.ADVISOR:
          response = await UserService.registrationAdvisor(userInfo);
          break;
        default:
          break;
      }

      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchPatchUser = createAsyncThunk(
  'user/fetchPatchUser',
  async (userInfo: PatchUserType, { rejectWithValue }) => {
    try {
      const response = await UserService.updateUser(userInfo);

      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchChangeEmail = createAsyncThunk(
  'user/fetchChangeEmail',
  async ({ newEmail, password }: ChangeEmailType, { rejectWithValue }) => {
    try {
      const response = await UserService.changeEmail({ newEmail, password });

      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchGetUser = createAsyncThunk(
  'user/fetchGetUser',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UserService.getUser();
      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchAcceptMultipleTenantLegalDocuments = createAsyncThunk(
  'user/fetchAcceptMultipleTenantLegalDocuments',
  (tenantLegalDocumentIds: number[], { rejectWithValue }) =>
    UserService.acceptMultipleTenantLegalDocuments(
      tenantLegalDocumentIds,
    ).catch(rejectWithValue),
);

export const deleteSboAccount = createAsyncThunk(
  'user/deleteSboAccount',
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await UserService.deleteSboAccount(email);

      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
