import { TFunction } from 'i18next';

import {
  HeaderTabs,
  advisorHeaderTabs,
  unauthenticatedHeaderTabs,
  sboHeaderTabs,
} from '_constants/headerConstants';

import { UserRolesEnum } from '_types/user.interface';
import { TenantFeatureFlags, TenantTypeEnum } from '_types/tenant-settings';
import { UnauthHomePageConfigrationDataJSON } from '_types/tenant-settings/tenant-page-configuration/unauth-home-page';

const filterTabsWithTenantFeatureFlags = (
  tabs: HeaderTabs[],
  tenantFeatureFlags: TenantFeatureFlags,
) =>
  tabs.filter(
    (tab) => !tab.featureFlagField || tenantFeatureFlags[tab.featureFlagField],
  );

export const headerTabsConstantsFn = (
  role: UserRolesEnum | undefined,
  tenantFeatureFlags: TenantFeatureFlags | null | undefined,
  t: TFunction,
  tenantType?: TenantTypeEnum,
  pageConfiguration?: UnauthHomePageConfigrationDataJSON | null,
): HeaderTabs[] => {
  let headerTabsList: HeaderTabs[];

  switch (role) {
    case UserRolesEnum.ADVISORY_ORG_ADMIN:
    case UserRolesEnum.ADVISOR:
      headerTabsList = advisorHeaderTabs(t);
      break;
    case UserRolesEnum.SBO:
      headerTabsList = sboHeaderTabs(t);
      break;
    default:
      headerTabsList = unauthenticatedHeaderTabs(
        t,
        tenantType,
        pageConfiguration,
      );
      break;
  }

  if (!tenantFeatureFlags) {
    return headerTabsList;
  }

  return filterTabsWithTenantFeatureFlags(headerTabsList, tenantFeatureFlags);
};
