import React, { memo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Logo from 'app/components/Logo/Logo';
import HomePageTabs from 'app/components/HomePageTabs/HomePageTabs';

import { useAppSelector } from 'store/configureStore';

import { profileIdSelector } from 'store/selectors/profile';

import useTenantType from 'utils/hooks/useTenantType';
import { TenantTypeEnum } from '_types/tenant-settings';
import GeneralHeaderProfilePart from './GeneralHeaderProfilePart';

const GeneralHeader: React.FC = () => {
  const navigate = useNavigate();
  const onLogoClick = useCallback(() => navigate('/'), [navigate]);

  const profileId = useAppSelector(profileIdSelector);
  const tenantType = useTenantType();
  const path = useLocation();

  return (
    <div className="header_wrapper">
      <Logo className="logo" onClick={onLogoClick} />

      {profileId && <HomePageTabs />}
      {!profileId &&
        tenantType !== TenantTypeEnum.VERIZON &&
        path?.pathname === '/' && <HomePageTabs isForUnAuthHomePage />}

      <GeneralHeaderProfilePart />
    </div>
  );
};

export default memo(GeneralHeader);
