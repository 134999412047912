/* eslint-disable implicit-arrow-linebreak */
import { RootState } from 'store/configureStore';

export const showResetPasswordModalSelector = (state: RootState) =>
  state.modals.showResetPasswordModal;
export const showLoginModalSelector = (state: RootState) =>
  state.modals.showLoginModal;
export const showResetEmailModalSelector = (state: RootState) =>
  state.modals.showResetEmailModal;
export const showRateSessionModalSelector = (state: RootState) =>
  state.modals.showRateSessionModal;
export const showInfoModalSelector = (state: RootState) =>
  state.modals.showInfoModal;
export const showGeneralModalSelector = (state: RootState) =>
  state.modals.showGeneralModal;
export const showFundingModalSelector = (state: RootState) =>
  state.modals.showNonDigitalFundingInfoModal;
export const showProfileMissingDataModalSelector = (state: RootState) =>
  state.modals.showProfileMissingDataModal;
export const showProfileMissingDataSuccessModalSelector = (state: RootState) =>
  state.modals.showProfileMissingDataSuccessModal;
export const showExternalUrlConsentModalSelector = (state: RootState) =>
  state.modals.showExternalUrlConsentModal;
export const showFundingExpandedViewModalSelector = (state: RootState) =>
  state.modals.showFundingCardExpandedViewModal;
export const showMenuModalSelector = (state: RootState) =>
  state.modals.showMenuModal;
export const showCalculatorModalSelector = (state: RootState) =>
  state.modals.showCalculatorModal;
export const showAccountDeletionModalSelector = (state: RootState) =>
  state.modals.showAccountDeletionModal;
